module.exports = {
  blogPostDir: 'posts', // The name of directory that contains your posts.
  blogAuthorDir: 'settings', // The name of directory that contains your 'authors' folder.
  blogAuthorId: 'ryonkmr', // The default and fallback author ID used for blog posts without a defined author.
  siteTitle: 'ryonkmr.com', // Site title.
  siteTitleAlt: '', // Alternative site title for SEO.
  siteLogo: '/logos/logo-512x512.png', // Logo used for SEO and manifest. e.g. '/logos/logo-1024.png',
  siteUrl: 'https://ryonkmr.com', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-casper/.
  siteDescription: 'ryonkmr.com', // Website description used for RSS feeds/meta description tag.
  siteCover: '/images/cover02.jpg', // Optional, the cover image used in header for home page. e.g: '/images/blog-cover.jpg',
  siteNavigation: true, // If navigation is enabled the Menu button will be visible
  siteRss: '/rss.xml', // Path to the RSS file.
  siteRssAuthor: 'ryonkmr', // The author name used in the RSS file
  // siteFBAppID: '1825356251115265', // optional, sets the FB Application ID for using app insights
  sitePaginationLimit: 10, // The max number of posts per page.
  googleAnalyticsID: 'UA-104533564-1', // GA tracking ID.
  // disqusShortname: 'https-vagr9k-github-io-gatsby-advanced-starter', // enables Disqus comments, visually deviates from original Casper theme.
  siteSocialUrls: [
    'https://github.com/RyoNkmr',
    'https://twitter.com/RyoNkmr_',
    // 'https://www.npmjs.com/~ryonkmr',
  ],
  postDefaultCategoryID: 'Tech', // Default category for posts.
  // Links to social profiles/projects you want to display in the navigation bar.
  userLinks: [
    {
      label: 'GitHub',
      url: 'https://github.com/RyoNkmr',
      iconClassName: 'fa fa-github', // Disabled, see Navigation.jsx
    },
    {
      label: 'twitter',
      url: 'https://twitter.com/RyoNkmr_',
      iconClassName: 'fa fa-twitter', // Disabled, see Navigation.jsx
    },
    {
      label: 'npm',
      url: 'https://www.npmjs.com/~ryonkmr',
      iconClassName: 'fa fa-npm', // Disabled, see Navigation.jsx
    },
  ],
  // Copyright string for the footer of the website and RSS feed.
  copyright: {
    label: 'RyoNkmr', // Label used before the year
    // year: '2018-', // optional, set specific copyright year or range of years, defaults to current year
    // url: 'https://www.gatsbyjs.org/' // optional, set link address of copyright, defaults to site root
  },
  themeColor: '#c62828', // Used for setting manifest and progress theme colors.
  backgroundColor: '#e0e0e0', // Used for setting manifest background color.
  promoteGatsby: true, // Enables the GatsbyJS promotion information in footer.
};
